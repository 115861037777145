import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const SWrapper = styled.div`
    display: flex;
    border: 1px solid ${(props) => props.theme.color.grey_900};
    border-bottom: ${(props) =>
        props.borderBottom
            ? `1px solid ${props.theme.color.grey_900}`
            : 'none'};
`;

export const SLeftCol = styled(Col)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px;

    @media (max-width: 768px) {
        padding: 32px;
    }
`;
export const SRightCol = styled(Col)`
    padding: 48px;

    @media (max-width: 768px) {
        padding: 32px;
    }
`;

export const SDate = styled.span`
    font-size: ${(props) => props.theme.fontSize.regular};
    color: ${(props) => props.theme.color.grey_600};
    line-height: ${(props) => props.theme.lineHeight.small};
`;
export const SImage = styled(GatsbyImage)`
    min-width: auto;
    min-height: 350px;
`;
export const STitle = styled.h3`
    margin: 8px 0 24px 0;
`;
export const SDescription = styled.div`
    color: ${(props) => props.theme.color.grey_600};
    margin-bottom: 40px;
`;
export const SLink = styled(Link)`
    font-size: ${(props) => props.theme.fontSize.large};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    line-height: ${(props) => props.theme.lineHeight.redular};
    color: ${(props) => props.theme.color.primary};

    svg {
        margin-left: 10px;
    }

    &:hover {
        color: ${(props) => props.theme.color.grey_900};

        svg > path{
            stroke: ${(props) => props.theme.color.grey_900};
        }
    }
`;
