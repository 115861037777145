import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

const squareCSS = css`
    height: 40px;
    width: 40px;
    border-right: 1px solid #000;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    border-left: ${(props) =>
        props.isRightBordered ? '1px solid #000' : null};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.secondary};
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
`;

export const SSection = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    /* margin-bottom: 25px; */
`;

export const SPaginationLink = styled(Link)`
    ${squareCSS}

    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        height: 30px;
        width: 30px;
    }

    &:hover:not(.disabled),
    &.active {
        background-color: ${(props) => props.theme.black};
        color: #000;
        font-weight: bold;
    }
    .arrow-svg {
        fill: ${(props) => props.theme.secondary};
    }

    &:hover .arrow-svg:not(.disabled) {
        fill: #000;
        cursor: pointer;
    }
    &.disabled,
    &.disabled:hover {
        background-color: transparent;

        & .arrow-svg {
            fill: ${(props) => props.theme.dark};
        }
    }
`;

export const SHiddenLinks = styled.span`
    ${squareCSS}
`;
