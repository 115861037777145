import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SSection, SPaginationLink, SHiddenLinks } from './styles';
import RightArrow from '../../images/svg/rightArrow.svg';
import LeftArrow from '../../images/svg/leftArrow.svg';

const Pagination = ({
    currentPage = 1,
    totalPosts = 0,
    pageSize = 5,
    basePath = '/naujienos',
}) => {
    const [linkIndexes, setLinkIndexes] = useState([]);
    const totalPage = Math.ceil(totalPosts / pageSize);

    useEffect(() => {
        // Build an array of integers with a length of the possible amount of pages
        const totalPageArr = Array.from({ length: totalPage }).map(
            (el, i) => i + 1
        );

        // We should have 1,2,3,4 if we are just starting from 1
        let showableIndexes = [...totalPageArr].splice(0, pageSize);
        // We should have 9, 10, 11, 12 if we are at/towards the end.
        if (totalPage > pageSize) {
            if (currentPage === totalPage || currentPage >= totalPage - 2) {
                showableIndexes = [...totalPageArr].splice(
                    totalPage - pageSize,
                    pageSize
                );
            }
        }

        // if the page is greater than {pageSize} Or current page is not in the range of the last page numbers. Simply, in the middle of above situations
        // e.g page number is 4, we want 3,4,5,6
        // e.g page number is 8, we want 7,8,9,10

        if (currentPage > pageSize - 1 && currentPage < totalPage - 2) {
            showableIndexes = [...totalPageArr].splice(
                currentPage - 2,
                pageSize
            );
        }
        setLinkIndexes(showableIndexes);
    }, []);

    return (
        <SSection>
            {totalPosts > pageSize ? (
                <>
                    {currentPage !== 1 && (
                        <SPaginationLink
                            to={`${basePath}/${
                                currentPage === 1 ? '1' : currentPage - 1
                            }/`}
                            className={currentPage === 1 ? 'disabled' : ''}
                            isRightBordered
                        >
                            <LeftArrow />
                        </SPaginationLink>
                    )}

                    {!linkIndexes.includes(1) && (
                        <>
                            <SPaginationLink to={`${basePath}/1/`}>
                                1
                            </SPaginationLink>
                            <SPaginationLink to={`${basePath}/2/`}>
                                2
                            </SPaginationLink>
                            {totalPage > pageSize && (
                                <SHiddenLinks>...</SHiddenLinks>
                            )}
                        </>
                    )}
                    {linkIndexes.map((linkIndex) => {
                        if (currentPage === 1 && linkIndex === 1) {
                            return (
                                <SPaginationLink
                                    key={linkIndex}
                                    to={`${basePath}/${linkIndex}/`}
                                    className={
                                        linkIndex === currentPage
                                            ? 'active'
                                            : ''
                                    }
                                    isRightBordered
                                >
                                    {linkIndex}
                                </SPaginationLink>
                            );
                        }
                        return (
                            <SPaginationLink
                                key={linkIndex}
                                to={`${basePath}/${linkIndex}/`}
                                className={
                                    linkIndex === currentPage ? 'active' : ''
                                }
                            >
                                {linkIndex}
                            </SPaginationLink>
                        );
                    })}

                    {!linkIndexes.includes(totalPage) && (
                        <>
                            {/* Only show this if the total page is more than pagesize */}
                            {totalPage > pageSize && (
                                <SHiddenLinks>...</SHiddenLinks>
                            )}
                            <SPaginationLink
                                to={`${basePath}/${totalPage}/`}
                                isRightBordered={currentPage === totalPage}
                            >
                                {totalPage}
                            </SPaginationLink>
                        </>
                    )}

                    {currentPage !== totalPage && (
                        <SPaginationLink
                            className={
                                currentPage === totalPage ? 'disabled' : ''
                            }
                            to={`${basePath}/${
                                currentPage === totalPage
                                    ? currentPage
                                    : currentPage + 1
                            }/`}
                        >
                            <RightArrow />
                        </SPaginationLink>
                    )}
                </>
            ) : null}
        </SSection>
    );
};

Pagination.propTypes = {
    pageSize: PropTypes.number,
    currentPage: PropTypes.number,
    totalPosts: PropTypes.number,
    basePath: PropTypes.string,
};

export default Pagination;
