import { Link } from 'gatsby';
import styled from 'styled-components';

export const SReadMoreButton = styled(Link)`
    width: auto;
    color: ${(props) => props.theme.color.primary};
    font-size: ${(props) => props.theme.fontSize.large};
    line-height: ${(props) => props.theme.lineHeight.regular};
   font-weight: ${(props) => props.theme.fontWeight.medium};
    margin-top: 40px;

    svg > path {
        stroke: ${(props) => props.theme.color.primary};
    }
`;
