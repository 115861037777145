import { getImage } from 'gatsby-plugin-image';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import NewsCard from '../../components/NewsCard';
import { SSectionH2Title, StyledContainer } from '../Layout/styles';
import Breadcrumbs from '../../components/Breadcrumbs';
import RightArrow from '../../images/svg/arrow.svg';
import { SReadMoreButton } from './styles';

const NewsContainer = ({
    blogPosts,
    isBreadcrumbVisible = false,
    isHomePage = false,
}) => {
    return (
        <StyledContainer fluid>
            <Row className="justify-content-center">
                {isBreadcrumbVisible && <Breadcrumbs />}
                <SSectionH2Title>Naujienos</SSectionH2Title>
                {blogPosts?.map((post, idx) => {
                    const image = post.featuredImage
                        ? getImage(post.featuredImage.node.localFile)
                        : null;
                    return (
                        <Col key={post.id} xs={12} className="p-0">
                            <NewsCard
                                title={post.title}
                                datePosted={post.date}
                                description={post.excerpt}
                                imageSrc={image}
                                imageAlt={post.title}
                                linkText="Sužinokite daugiau"
                                linkURL={`/naujienos/${post.slug}/`}
                                isImageLeft={idx % 2 === 0}
                                borderBottom={blogPosts.length - 1 === idx}
                            />
                        </Col>
                    );
                })}
                {isHomePage && (
                    <SReadMoreButton to="/naujienos">
                        Pamatykite daugiau <RightArrow />
                    </SReadMoreButton>
                )}
            </Row>
        </StyledContainer>
    );
};

export default NewsContainer;
