import React from 'react';
import Pagination from '../components/Pagination';
import SEO from '../components/SEO';
import NewsContainer from '../containers/NewsContainer';
import PropTypes from 'prop-types';

const Naujienos = ({ pageContext, posts = null }) => {
    return (
        <>
            <SEO
                title={`Naujienos | FSA`}
                description={''}
                useTitleTemplate={false}
            />
            <NewsContainer
                blogPosts={posts || pageContext?.posts}
                isBreadcrumbVisible={true}
            />
            <Pagination
                currentPage={pageContext?.pageNumber}
                totalPosts={pageContext?.totalPosts}
                basePath={'/naujienos'}
                pageSize={5}
            />
        </>
    );
};

Naujienos.propTypes = {
    data: PropTypes.object,
    currentPage: PropTypes.number,
    totalPosts: PropTypes.number,
};

export default Naujienos;
