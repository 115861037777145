import React from 'react';
import {
    SWrapper,
    SImage,
    STitle,
    SDescription,
    SLink,
    SLeftCol,
    SRightCol,
    SDate,
} from './styles';
import ArrowIcon from '../../images/svg/arrow.svg';
import { Row } from 'react-bootstrap';

const NewsCard = ({
    title,
    description,
    imageSrc,
    imageAlt,
    linkText,
    linkURL,
    datePosted,
    isImageLeft,
    ...props
}) => {
    return (
        <SWrapper {...props}>
            <Row className="align-items-center w-100">
                <SLeftCol
                    xs={12}
                    md={6}
                    className={
                        isImageLeft
                            ? 'order-1 order-md-1'
                            : 'order-1 order-md-2'
                    }
                >
                    <SImage image={imageSrc} alt={imageAlt} />
                </SLeftCol>
                <SRightCol
                    xs={12}
                    md={6}
                    className={
                        isImageLeft
                            ? 'order-2 order-md-2'
                            : 'order-2 order-md-1'
                    }
                >
                    <SDate>{datePosted}</SDate>
                    <STitle>{title} </STitle>
                    <SDescription>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: description,
                            }}
                        ></div>
                    </SDescription>
                    <SLink to={linkURL}>
                        {linkText}
                        <ArrowIcon />
                    </SLink>
                </SRightCol>
            </Row>
        </SWrapper>
    );
};

export default NewsCard;
